import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-responsive',
  templateUrl: './table-responsive.component.html',
  styleUrls: ['./table-responsive.component.scss']
})
export class TableResponsiveComponent {

  @Input() items = []; // decorate the property with @Input()
  @Input() columns: string[] = []; // decorate the property with @Input()
  @Input() validators: any[] = [];
  @Input() values: any[] = [];
  @Input() actions: string[] = []; 

  @Output() deleteStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDialogs: EventEmitter<any> = new EventEmitter<any>();

  showed: number = null;
  url: string;

  validateStatus(id: any, status: boolean): void {
    if( !status ){
      this.activeStatus.emit(id);
    }else{
      this.deleteStatus.emit(id);
    }
  }

  openDialogo(id){
    this.openDialog.emit(id);
  }

  openDialogoSign(dialog, id){
    this.openDialogs.emit({id});
  }

  showMore(index: number){

    const row = document.getElementById("row-"+index) as HTMLElement;

    if( row.classList.contains("fila") ){
      row.classList.remove("fila");
      this.showed = index;
    }else{
      this.showed = null;
      row.classList.add("fila");
    }
  }

  navigate(id: any){
    this._router.navigateByUrl( this.url+"/edit/"+id );
  }

  seeDetails(id: any){
    this._router.navigateByUrl( this.url+"/"+id );
  }

  seeDocument(id: any){
    this._router.navigateByUrl( "/documents/see-document/"+id );
  }

  seeDocumento(doc: any){
    this._router.navigateByUrl( "/documents/see-document/"+doc.document.id );
  }



  constructor(private _router: Router,){
    this.url = _router.url;
  }

}
