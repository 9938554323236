import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AdminGuard } from './core/auth/guards/admin.guard';
import { BusinessGuard } from './core/auth/guards/business.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

  // Auth routes for guests
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'account-response', loadChildren: () => import('app/modules/auth/account-response/account-response.module').then((m) => m.AuthAccountResponseModule) },
      { path: 'activation-account', loadChildren: () => import('app/modules/auth/activation-account/activation-account.module').then((m) => m.AuthActivationAccountModule) },
      { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then((m) => m.AuthConfirmationRequiredModule) },
      { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule) },
      { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule) },
      { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule) },
      { path: 'complete-profile', loadChildren: () => import('app/modules/auth/complete-profile/complete-profile.module').then((m) => m.CompleteProfileModule) },
      { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule) },
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule) },
      { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule) },
      { path: 'validate-document', loadChildren: () => import('app/modules/auth/validate-document/validate-document.module').then((m) => m.ValidateDocumentModule) },
      { path: 'document', loadChildren: () => import('app/modules/business/signatures/signatures.module').then((m) => m.SignaturesModule) },
      { path: 'terms-and-cond', loadChildren: () => import('app/modules/auth/terms-and-cond/terms-and-cond.module').then((m) => m.TermsAndCondModule) },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule) },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      { path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then((m) => m.ExampleModule) },
      { path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then((m) => m.UsersModule) },
      { path: 'profile', loadChildren: () => import('app/modules/admin/pages/settings/settings.module').then((m) => m.SettingsModule) },
      { path: 'newdocuments', loadChildren: () => import('app/modules/admin/pages/mailbox/mailbox.module').then((m) => m.MailboxModule) },
      { path: 'documents', loadChildren: () => import('app/modules/business/documents/documents.module').then((m) => m.DocumentsModule) },
      { path: 'templates', loadChildren: () => import('app/modules/business/templates/templates.module').then((m) => m.TemplatesModule) },
      { path: 'signatures', loadChildren: () => import('app/modules/business/signatures/signatures.module').then((m) => m.SignaturesModule) },
      { path: 'notifications', loadChildren: () => import('app/modules/business/notifications/notifications.module').then((m) => m.NotificationsModule) },
      { path: 'visualizations', loadChildren: () => import('app/modules/business/visualizations/visualizations.module').then((m) => m.VisualizationsModule) },
      { path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule) },
      { path: 'validate-document', loadChildren: () => import('app/modules/auth/validate-document/validate-document.module').then((m) => m.ValidateDocumentModule) },
    ],
  },

  {
    path: '',
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      { path: 'plans', loadChildren: () => import('app/modules/admin/plans/plans.module').then((m) => m.PlansModule) },
    ],
  },

  {
    path: '',
    canActivate: [BusinessGuard],
    canActivateChild: [BusinessGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      { path: 'company', loadChildren: () => import('app/modules/business/company.module').then((m) => m.CompanyModule) },
      { path: 'company2', loadChildren: () => import('app/modules/admin/pages/organization/organization.module').then((m) => m.OrganizationModule) },
    ],
  },

];
