<!-- Button -->

<button mat-icon-button *ngIf="userData?.roles === 'business'" [matTooltip]="'Añadir usuario'" (click)="addUser()">
	<img style="width: 24px; height: 24px;" src="../../../assets/icons/ico-add-user.svg" alt="">
</button>
<a mat-icon-button [matTooltip]="'Tutoriales'"
	href="https://www.sistemascontino.com.mx/miplumadigital_contisign/tutoriales.php" target="_blank">
	<img style="width: 24px; height: 24px;" src="../../../assets/icons/ico-tutorials.svg" alt="">
</a>

<button mat-icon-button [matTooltip]="'Tutorial'" (click)="openSupport(content1)" id="videoButton"
	style="display:none !important;">
	<img style="width: 24px; height: 24px;" src="../../../assets/icons/ico-tutorials.svg" alt="">
</button>

<a mat-icon-button href="https://wa.link/hjcynq" target="_blanky" [matTooltip]="'Soporte Contisign'">
	<img style="width: 24px; height: 24px;" src="../../../assets/icons/ico-support.svg" alt="">
</a>

<button mat-icon-button (click)="openSupport(content2)" [matTooltip]="'Soporte'">
	<mat-icon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
</button>

<button mat-icon-button [matTooltip]="tooltip || 'Toggle Fullscreen'" (click)="toggleFullscreen()">
	<ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>
<!-- Default icon -->
<ng-template #defaultIconTpl>
	<mat-icon [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
</ng-template>


<ng-template #content3>
	<div class="flex flex-col max-w-fit md:min-w-160 max-h-screen -m-6">
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg_primary text-on-primary">
			<div class="text-lg font-medium">Soporte {{ company.BusinessName }}</div>
			<button mat-icon-button (click)="close()" [tabIndex]="-1">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
			</button>
		</div>
		<div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto mt-0">
			<section class="bg-white pb-20 lg:py-[10px]">
				<div class="container">
					<div class="flex flex-wrap -mx-4">
						<div class="w-full px-4">
							<h4 class="relative">Nombre: <b class="absolute right-0">{{ company.Name }}</b></h4>
							<h4 class="relative">Correo: <b class="absolute right-0">{{ company.ContactEmail }}</b></h4>
							<h4 class="relative">Celular: <b class="absolute right-0">{{ company.MobilePhone }}</b></h4>
							<h4 class="relative">Teléfono: <b class="absolute right-0">{{ company.OfficePhone }}</b>
							</h4>
						</div>
					</div>
				</div>
			</section>

			<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
				<div class="-ml-2">
				</div>
				<div class="flex items-center mt-4 sm:mt-0">
					<button class="sm:mx-3 py-3 px-4 c-button" (click)="close()">
						<span>Cerrar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #content2>
	<div class="flex flex-col max-w-fit md:min-w-160 max-h-screen -m-6">
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg_primary text-on-primary">
			<div class="text-lg font-medium">Soporte Contisign</div>
			<button mat-icon-button (click)="close()" [tabIndex]="-1">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
			</button>
		</div>
		<div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto mt-0">
			<section class="bg-white pb-20 lg:py-[10px]">
				<div class="container">
					<div class="flex flex-wrap -mx-4">
						<div class="w-full px-4">
							<h4 class="relative">Nombre: <b class="absolute right-0">Gustavo Ixtepan</b></h4>
							<h4 class="relative">Correo: <b class="absolute right-0">soporte@contisign.com.mx</b></h4>
							<h4 class="relative">Celular: <b class="absolute right-0">(229) 552 8204</b></h4>
							<h4 class="relative">Teléfono: <b class="absolute right-0">(800) 837 5001</b>
							</h4>
						</div>
					</div>
				</div>
			</section>

			<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
				<div class="-ml-2">
				</div>
				<div class="flex items-center mt-4 sm:mt-0">
					<button class="sm:mx-3 py-3 px-4 c-button" (click)="close()">
						<span>Cerrar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #content1>
	<div class="flex flex-col max-w-fit md:min-w-160 max-h-screen -m-6">
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg_primary text-on-primary">
			<div class="text-lg font-medium">¿Cómo usar tu pluma digital?</div>
			<button mat-icon-button (click)="close2()" [tabIndex]="-1">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
			</button>
		</div>
		<div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto mt-0">
			<section class="bg-white pb-20 lg:py-[10px]">
				<div class="container">
					<div class="flex flex-wrap -mx-4">
						<div class="w-full px-4">
							<iframe width="560" height="315"
								src="https://www.youtube.com/embed/xgKvy9DiRcA?si=-WFTVvOiZeKW3tHi&autoplay=1&mute=0"
								title="YouTube video player" frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</section>

			<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
				<div class="-ml-2">
				</div>
				<div class="flex items-center mt-4 sm:mt-0">
					<button class="sm:mx-3 py-3 px-4 c-button" (click)="close2()">
						<span>Cerrar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>