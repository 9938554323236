import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputBase } from 'app/shared/interface/inputBase';
import { QuestionControlService } from 'app/shared/services/question-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService],
})
export class DynamicFormComponent implements OnInit {
  @Input() questions: InputBase<string>[] | null = [];

  form!: FormGroup;

  payLoad = '';

  constructor(private qcs: QuestionControlService) {}

  ngOnInit() {
    /* console.log("Desde el input",this.questions) */
    this.form = this.qcs.toFormGroup(this.questions as InputBase<string>[]);
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }
}
