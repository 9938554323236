import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree,
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
       * Constructor
       */
    constructor(
        private _authService: AuthService,
        private _router: Router,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
       * Can activate
       *
       * @param route
       * @param state
       */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (localStorage['currentUser']) {
            const user = JSON.parse(localStorage['currentUser']);
            if (user.roles === 'admin') {
                return true;
            }
            this._router.navigate(['dashboard']); // go to login if not authenticated
            return true;
        }
        this._router.navigate(['sign-in']); // go to login if not authenticated
        return true;
    }

    /**
       * Can activate child
       *
       * @param childRoute
       * @param state
       */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
       * Can load
       *
       * @param route
       * @param segments
       */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
       * Check the authenticated status
       *
       * @param redirectURL
       * @private
       */
    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((authenticated) =>


                    // If the user is not authenticated...
                    of(true))
            );
    }
}
