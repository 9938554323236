import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from 'app/modules/auth/auth.models';
import { environment } from 'environments/environment';
import { CompanyModel, UserCompanyModel } from 'app/modules/business/business.models';
import { getHeaders } from '../utils/headers.functions';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  updateFirstLogin(id: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/update-login/${id}`, { headers });
  }


  public http: HttpClient;

  constructor(private https: HttpClient) {
    this.http = https;
  }

  updateProfile(body: any, id: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/profile/${id}`, body, { headers });
  }

  updateUserById(body: { user: { firstName: string; lastName: string; Active: boolean; }; permitions: []; }, id: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/userbyId/${id}`, body, { headers });
  }

  editUserCompany(userId: any, body: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${userId}`, body, { headers });
  }

  editUserPermition(userId: any, body: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/permition/${userId}`, body, { headers });
  }

  updateCatalog(positionSelected: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/catalog/${positionSelected.id}`, positionSelected, { headers });
  }

  getInfoUser(userId: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/auth/presignup/${userId}`, { headers });
  }

  createUsers(companyId: string, formData: FormData) {
    return this.http.post<any>(`${BASE_URL}/users-file?personId=${companyId}`, formData);
  }

  createElements(companyId: string, formData: FormData) {
    return this.http.post<any>(`${BASE_URL}/element-file`, formData);
  }

  deleteUser(id: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<any>(`${BASE_URL}/user/${id}`, { headers });
  }

  activateUser(id: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/user/${id}`, { headers });
  }

  public getUsers(limit: number, offset: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=${limit}&offset=${offset}`, { headers });
  }

  getUserFilter(limit: any, offset: any, filterData: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/users?limit=${limit}&offset=${offset}${filterData}`, { headers });
  }

  getUserById(id: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/userbyId/${id}`, { headers });
  }

  setPermitions(permitions: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/permitionss`, permitions, { headers });
  }

  completePresign(userId: any, body: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/presignup/${userId}`, body, { headers });
  }


  editCompany(id: string, formData: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/business/${id}`, formData, { headers });
  }

  public getUser(userId: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/user/${userId}`, { headers });
  }

  public getBusiness(limit: number, offset: number, filter: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business?limit=${limit}&offset=${offset}${filter}`, { headers });
  }

  getCatalogosPermissions() {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogtype/Permissions`, { headers });
  }

  changePass(idUser: string, body: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/changepass/${idUser}`, body, { headers });
  }

  public getBusinessUsers(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/businessL`, { headers });
  }

  public createUser(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/addnewuser`, user, { headers });
  }

  public updateUser(user: any, userId: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${userId}`, user, { headers });
  }

  public createCompany(company: CompanyModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/business`, company, { headers });
  }

  public getCompany(companyId: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/get-company/${companyId}`, { headers });
  }

  getCompanyById(idBusiness: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business/${idBusiness}`, { headers });
  }

  public createUserCompany(user: UserCompanyModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/inviteusers`, user, { headers });
  }

  public getEmployees(businessId: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/employees/${businessId}`, { headers });
  }

  public getPositions(companyId: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/positionsBus/${companyId}`, { headers });
  }

  public getCatalogosLists(value: string, companyId: string, filter: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogitems/${value}?businessId=${companyId}${filter}`, { headers });
  }

  public getCatalogosPositions(companyId: string, filter: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogitems/Positions?businessId=${companyId}${filter}`, { headers });
  }

  public getCatalogosCampos(companyId: string, filter: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogitems/Campos?businessId=${companyId}${filter}`, { headers });
  }

  public getCatalogosDepartments(companyId: string, filter: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogitems/Departments?businessId=${companyId}${filter}`, { headers });
  }

  public getCatalogosGroups(companyId: string, filter: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogitems/Groups?businessId=${companyId}${filter}`, { headers });
  }

  public createPosition(position: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/catalogs`, position, { headers });
  }

  public createCatalog(department: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/catalogs`, department, { headers });
  }

  public createGroup(groups: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/catalogs`, groups, { headers });
  }

  public deletePosition(positionId: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<any>(`${BASE_URL}/positions/${positionId}`, { headers });
  }

  public getConsumables(userId: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/userconsumables/${userId}`, { headers });
  }

  /* public mapRequiredValues(company: any): any {
    const companyInfo: any = {};

    companyInfo.name = company.Name;
    companyInfo.rfc = company.TaxId;
    companyInfo.regime = company.TaxRegime;
    companyInfo.type = company.LocalType;
    companyInfo.phone = company.MobilePhone;
    companyInfo.phoneoffice = company.OfficePhone;
    companyInfo.email = company.ContactEmail;
    companyInfo.description = company.Description;
    // companyInfo.MobilePhone = company.MobilePhone;

    return companyInfo;
  } */
}
