/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  // {
  //     id   : 'example',
  //     title: 'Example',
  //     type : 'basic',
  //     icon : 'heroicons_outline:chart-pie',
  //     link : '/example'
  // },
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
  {
    id: 'newdocument',
    title: 'Nuevo Documento',
    type: 'basic',
    icon: 'heroicons_outline:plus-circle',
    link: '/documents/add-document',
  },
  {
    id: 'documents',
    title: 'Documentos',
    icon: 'heroicons_outline:document-text',
    type: 'collapsable',
    children: [
      {
        id: 'generated',
        title: 'Enviados',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/documents',
      },
      {
        id: 'signatures',
        title: 'Recibidos',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/signatures',
      },
      {
        id: 'notifications',
        title: 'Notificados',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/notifications',
      },
      {
        id: 'visualization',
        title: 'Archivados',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/visualizations',
      },
    ]
  },
  {
    id: 'portafolio',
    title: 'Documentos',
    icon: 'heroicons_outline:document-text',
    type: 'collapsable',
    children: [
      {
        id: 'generated',
        title: 'Enviados',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/documents',
      },
      {
        id: 'signatures',
        title: 'Recibidos',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/signatures',
      },
      {
        id: 'notifications',
        title: 'Notificados',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/notifications',
      },
      {
        id: 'visualization',
        title: 'Archivados',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/visualizations',
      },
    ]
  },
  {
    id: 'template',
    title: 'Plantillas',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/templates',
  },
  {
    id: 'plans',
    title: 'Planes',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/plans',
  },
  {
    id: 'users',
    title: 'Usuarios',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/users',
    exactMatch: true,
  },
  {
    id: 'business',
    title: 'Organizaciones',
    type: 'basic',
    icon: 'heroicons_outline:briefcase',
    link: '/users/business',
  },
  /* {
        id   : 'company',
        title: 'Catálogos',
        type : 'basic',
        icon : 'heroicons_outline:office-building',
        link : '/company',
        exactMatch: true
    }, */
  {
    id: 'config',
    title: 'Mi Organización',
    type: 'collapsable',
    icon: 'heroicons_outline:bookmark-alt',
    children: [
      {
        id: 'company',
        title: 'Datos generales',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/company',
      },
      {
        id: 'positions',
        title: 'Puestos',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/company/positions',
      },
      {
        id: 'deparments',
        title: 'Departamentos',
        type: 'basic',
        icon: 'heroicons_outline:bookmark-alt',
        link: '/company/departaments',

      },
      {
        id: 'groups',
        title: 'Sucursales',
        type: 'basic',
        active: false,
        icon: 'heroicons_outline:bookmark-alt',
        link: '/company/groups',
      },
      {
        id: 'lists',
        title: 'Listas',
        type: 'basic',
        active: false,
        icon: 'heroicons_outline:bookmark-alt',
        link: '/company/list',
      },
      {
        id: 'users',
        title: 'Usuarios',
        type: 'basic',
        active: false,
        icon: 'heroicons_outline:bookmark-alt',
        link: '/company/users',
      },
    ],
  },
  {
    id: 'validator',
    title: 'Validador de documentos',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/validate-document',
  },
  {
    id: 'profile',
    title: 'Perfil',
    type: 'basic',
    icon: 'heroicons_outline:user-circle',
    link: '/profile',
  },
  {
    id: 'sign-out',
    title: 'Cerrar sesión',
    type: 'basic',
    icon: 'heroicons_outline:logout',
    link: '/sign-out',
  },

];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
  {
    id: 'plans',
    title: 'Planes',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/plans',
  },
  {
    id: 'users',
    title: 'Usuarios',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/users',
  },
  {
    id: 'company',
    title: 'Mi Empresa',
    type: 'basic',
    icon: 'heroicons_outline:office-building',
    link: '/company',
  },
  {
    id: 'validator',
    title: 'Validador de documentos',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/validate-document',
  }
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
  {
    id: 'plans',
    title: 'Planes',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/plans',
  },
  {
    id: 'users',
    title: 'Usuarios',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/users',
  },
  {
    id: 'company',
    title: 'Mi Empresa',
    type: 'basic',
    icon: 'heroicons_outline:office-building',
    link: '/company',
  },
  {
    id: 'validator',
    title: 'Validador de documentos',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/validate-document',
  }
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
  {
    id: 'plans',
    title: 'Planes',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/plans',
  },
  {
    id: 'users',
    title: 'Usuarios',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/users',
  },
  {
    id: 'company',
    title: 'Mi Empresa',
    type: 'basic',
    icon: 'heroicons_outline:office-building',
    link: '/company',
  },
  {
    id: 'validator',
    title: 'Validador de documentos',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/validate-document',
  }
];
