import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject, catchError, Observable, of, switchMap, throwError,
} from 'rxjs';
import { map } from 'rxjs/operators';
// import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { UserModel, AuthModel, LoginModel } from 'app/modules/auth/auth.models';
import { environment } from 'environments/environment';
import { getHeaders } from 'app/shared/utils/headers.functions';
import { Router } from '@angular/router';
import { AuthUtils } from './auth.utils';
import moment from 'moment';

const BASE_URL: string = environment.API_URL;

@Injectable()
export class AuthService {
  isLoggedIn() {
    if( localStorage['currentUser'] ){
      const user = JSON.parse(localStorage['currentUser']);
      return user && moment().diff(user.expiresIn, 'minutes') > 0;
    }
    return true;
  }

  public _authenticated = false;

  private currentUserSubject: BehaviorSubject<AuthModel>;

  public currentUser: Observable<AuthModel>;

  public idTemplate:string;

  public where: string;

  /**
     * Constructor
   */
  constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  validateuser(user: { password: string; email: string; }) {
    return this._httpClient.post<any>(`${BASE_URL}/auth/validatepassword`, user);
  }

  checkPlan(id: any) {
    const headers: HttpHeaders = getHeaders();
    return this._httpClient.get<any>(`${BASE_URL}/checkPlan/${id}`, { headers });
  }

  public get currentUserValue(): AuthModel {
    return this.currentUserSubject.value;
  }

  signin(user: LoginModel) {
    return this._httpClient.post<any>(`${BASE_URL}/auth/signin`, user);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
     * Setter & getter for access token
     */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Sign up
     *
     * @param user
     */
  signUp(user: UserModel): Observable<any> {
    return this._httpClient.post<any>(`${BASE_URL}/auth/signup`, user);
  }

  checkMail(value: any) {
    const headers: HttpHeaders = getHeaders();
    return this._httpClient.post<any>(`${BASE_URL}/userbymail`, {email: value}, { headers });
  }

  validate(uuid: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this._httpClient.put<any>(`${BASE_URL}/auth/activate/${uuid}`, { headers });
  }

  /**
     * Sign in
     *
     * @param credentials
     */
  signIn(credentials: { email: string; password: string }): Observable<any> {

    /* console.log(this._authenticated); */

    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }
    return this._httpClient.post<any>(`${BASE_URL}/auth/signin`, credentials).pipe(
      switchMap((response: any) => {

        if( response.token ){
          // Store the access token in the local storage
          this.accessToken = response.token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          // this._userService.user = response.user;
        }
        // Return a new observable with the response
        return of(response);

      }),
    )
      .pipe(map((currentUser) => this.setUser(currentUser)));
  }

  private setUser(user: AuthModel) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }

  /**
     * Sign in using the access token
     */
  signInUsingToken(): Observable<any> {
    return this._httpClient.post('api/auth/refresh-access-token', {
      accessToken: this.accessToken,
    }).pipe(
      catchError(() =>

      // Return false
        of(false)),
      switchMap((response: any) => {
        /* console.log(response); */
        // Store the access token in the local storage
        /*                 this.accessToken = response.token;
 */
        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service

        // Return true
        return of(true);
      }),
    );
  }

  /**
     * Sign out
     */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    /* localStorage.clear(); */
    this.currentUserSubject.next(null);

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  public handleUnauthorizedError(err: any) {
    if (err.error && err.error.status === 401) {
      this.signOut();
      this._router.navigate(['/sign-in']);
    }

    return throwError('Session Expired');
  }

  /**
     * Forgot password
     *
     * @param email
     */
  forgotPassword(email: any): Observable<any> {
    // return this._httpClient.post('api/auth/forgot-password', email);
    return this._httpClient.put<any>(`${BASE_URL}/auth/resetpassword`, email);
  }

  /**
      * Reset password
      *
      * @param password
      */
  resetPassword(uuid: string, password: any): Observable<any> {
    return this._httpClient.put<any>(`${BASE_URL}/auth/change-password/${uuid}`, password);
    // return this._httpClient.post('api/auth/reset-password', password);
  }

  createPassword(token: string, password: any): Observable<any> {
    return this._httpClient.put<any>(`${BASE_URL}/activateaccountB/${token}`, password);
  }

  /**
     * Unlock session
     *
     * @param credentials
     */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
     * Check the authentication status
     */
  check(): Observable<boolean> {
    // Check if the user is logged in
    /* if ( this._authenticated )
        {
            return of(true);
        } */

    // Check the access token availability
    if (!this.accessToken || this.accessToken == 'undefined') {
      return of(false);
    }

    // Check the access token expire date
    /* if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
             return of(false);
        } */

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
    /*  return of(true); */
  }
}
