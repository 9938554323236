import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from 'app/core/form/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from 'app/core/form/dynamic-form-question/dynamic-form-question.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SanitizedHtmlPipe } from './utils/sanitized-html.pipe';
import { TableResponsiveComponent } from './table-responsive/table-responsive.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SanitizedHtmlPipe,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    TableResponsiveComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    SanitizedHtmlPipe,
    ReactiveFormsModule,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    TableResponsiveComponent,
    CKEditorModule,
  ],
})
export class SharedModule {
}
