import {
  Component, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { AuthModel } from 'app/modules/auth/auth.models';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;

  navigation: Navigation;

  user: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  currentUser: AuthModel;
  windowWidth: number;

  /**
     * Constructor
     */
  constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseNavigationService: FuseNavigationService,
        private _authService: AuthService,

  ) {

    this.windowWidth = window.innerWidth;
    
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
     * Getter for current year
     */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
     * On init
     */
  ngOnInit(): void {
    this._authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);
    // console.log(this._authService.currentUserValue);
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to the user service
    this._userService.user$
      .pipe((takeUntil(this._unsubscribeAll)))
      .subscribe((user: any) => {
        this.user = user;
        if( localStorage.getItem('currentUser') ){
          this.user.avatar = JSON.parse(localStorage.getItem('currentUser')).fileUrl ;
          this.user.email = localStorage.getItem('userEmail');
        }
      });

    // Subscribe to media changes
    /* this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        console.log(matchingAliases);
        this.isScreenSmall = !matchingAliases.includes('md');
      }); */

      const width = window.innerWidth;

      this.isScreenSmall = width <= 1024 ? true : false;

  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Toggle navigation
     *
     * @param name
     */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
