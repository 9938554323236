import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputBase } from 'app/shared/interface/inputBase';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
})
export class DynamicFormQuestionComponent {
  @Input() question!: InputBase<string>;

  @Input() form!: FormGroup;

  get isValid() { return this.form.controls[this.question.key].valid; }
}
