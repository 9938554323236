import { expandCollapse } from '@fuse/animations/expand-collapse';
import {
  fadeIn, fadeInBottom, fadeInLeft, fadeInRight,
  fadeInTop, fadeOut, fadeOutBottom, fadeOutLeft,
  fadeOutRight, fadeOutTop,
} from '@fuse/animations/fade';
import { shake } from '@fuse/animations/shake';
import {
  slideInBottom, slideInLeft, slideInRight, slideInTop,
  slideOutBottom, slideOutLeft, slideOutRight, slideOutTop,
} from '@fuse/animations/slide';
import { zoomIn, zoomOut } from '@fuse/animations/zoom';

export const fuseAnimations = [
  expandCollapse,
  fadeIn, fadeInTop, fadeInBottom, fadeInLeft, fadeInRight,
  fadeOut, fadeOutTop, fadeOutBottom, fadeOutLeft, fadeOutRight,
  shake,
  slideInTop, slideInBottom, slideInLeft, slideInRight,
  slideOutTop, slideOutBottom, slideOutLeft, slideOutRight,
  zoomIn, zoomOut,
];
